<template>
  <div class="flex flex-wrap w-full min-h-screen">
    <div class="grid grid-cols-2 w-full">
      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="flex justify-center" style="width: 40%">
          <img alt="logo" src="@/assets/images/sme_logo.png" />
        </div>
        <div class="flex flex-col gap-2" style="width: 60%">
          <h3 class="text-2xl text-center mt-4 mb-4">Log in to SmeSpot</h3>
          <div class="field">
            <InputText class="w-full" type="text" name="email" v-model="email" v-validate="'required|email'" placeholder="Email address" />
            <span class="block text-red-500 italic text-left">{{ errors.first('email') }}</span>
          </div>
          <div class="field">
            <InputText class="w-full" type="password" name="password" v-model="password" v-validate="'required'" placeholder="Password" />
            <span class="block text-red-500 italic text-left">{{ errors.first('password') }}</span>
          </div>
          <Button label="Log in" class="w-full p-button" @click.prevent="signIn" />
          <div class="flex flex-row items-center justify-center text-sm">
            <span>Forgot password?</span>
            <Button label="click here" class="p-button-sm p-button-link" @click.prevent="$emit('forgot-password')" />
          </div>
          <div class="flex flex-row justify-center gap-2 items-center">
            <Divider />
            <span>or</span>
            <Divider />
          </div>
          <Button class="p-button border-0 facebook w-full" @click.prevent="$emit('facebook-sign-in')" label="Continue with Facebook" />
          <Button class="p-button border-0 google w-full" @click.prevent="$emit('google-sign-in')" label="Continue with Google" />
          <div class="flex flex-row items-center justify-center text-sm">
            <span>Don't have an account?</span>
            <Button label="sign up" class="p-button-link p-button-sm" @click="$emit('sign-up')" />
          </div>
        </div>
      </div>
      <div class="flex flex-col items-center justify-center border-l border-black">
        <img alt="log-in" src="@/assets/images/sign-in.jpeg" style="width: 80%" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Divider from 'primevue/divider';

export default {
  components: {
    Divider,
    Button,
    InputText,
  },
  data() {
    return {
      email: '',
      password: '',
    };
  },
};
</script>

<style lang="css" scoped>
.facebook {
  background-color: #3b5998;
}

.facebook:hover {
  background-color: #294175;
}

.google {
  background-color: #dd4b39;
}

.google:hover {
  background-color: #c23321;
}
</style>
